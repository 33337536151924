<div mat-dialog-content class="superbold">You are going to change the asset model...</div>
<p class="text">
    When changing the asset model for this scout, remember to also change
    the asset model on the corresponding health sensor.
</p>

<div mat-dialog-actions>
    <button mat-button color="warn" (click)="onNoClick()">Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="true" (click)="yesClick()" cdkFocusInitial>Make
        Changes</button>
</div>
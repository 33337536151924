import {
  Component,
  OnInit,
  Input
} from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import { LngLat } from "mapbox-gl";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-asset-map",
  templateUrl: "./asset-map.component.html",
  styleUrls: ["./asset-map.component.scss"],
})
export class AssetMapComponent implements OnInit {
  // @ViewChild('daterange', {static: false}) DateRange: DateRangePicker;
  mMap: mapboxgl.Map;
  mMarkers: mapboxgl.Marker[] = [];
  mPopUp: mapboxgl.Popup;
  mAccessToken: string;

  size = 200;
  style: string;
  lat: number;
  lng: number;
  icon: any;
  startDate: Date;
  endDate: Date;
  count = 0;
  _coordinates: any[] = [];
  _installs: any[] = [];

  @Input()
  set installs(installs: any[]) {
    if (this.count < 3) {
      this._installs = installs;
      this.count++;
      //console.log(this.count);
    } else {
      this._installs = installs;
      this.refresh();
      this.count++;
      //console.log(this.count);
    }
  }
  get installs() {
    return this._installs;
  }

  @Input()
  set coordinates(val) {
    if (val[0]) {
      this._coordinates = val;
      const mid: LngLat = new LngLat(val[1], val[0]);
    }
  }

  get coordinates() {
    return this.coordinates;
  }

  constructor() {
    this.init();
    this.timeline();
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(
      this.mAccessToken
    );
  }

  ngOnInit() {
    this.buildMap();
    if (this._coordinates[1] != null && this.coordinates[0] != null) {
      this.mMap.flyTo({
        center: [this._coordinates[1], this._coordinates[0]],
      });
    }

    // this.buildMapControls(this.mMap);
  }

  init() {
    this.style = "mapbox://styles/mapbox/outdoors-v9";
    this.lat = 44.9765;
    this.lng = -93.2761;
    this.icon = {
      // iconScoutUrl: '/assets/images/ScoutMarker.png',
      iconJournalUrl: "",
      iconSize: [40, 50],
      iconAnchor: [0, 0],
      popupAnchor: [0, -25],
    };
    this.mAccessToken =
      "pk.eyJ1IjoiY29ubmVyMjIiLCJhIjoiY2pkYnU2aXFvMGFyczJ3czQ4OWE2b2J6YyJ9.aYqjWv3vWY-UQ5bNBwHQoQ";
  }

  timeline() {
    const currentDate = new Date();
    const lastYear = currentDate.getFullYear() - 1;
    this.startDate = new Date(lastYear);
    this.endDate = new Date(currentDate.toDateString());
  }

  pulsingDot: any = {
    width: this.size,
    height: this.size,
    data: new Uint8Array(this.size * this.size * 4),

    // get rendering context for the map canvas when layer is added to the map
    onAdd: function () {
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d");
    },

    // called once before every frame where the icon will be used
    render: function () {
      var duration = 1000;
      var t = (performance.now() % duration) / duration;

      var radius = (this.size / 2) * 0.3;
      var outerRadius = (this.size / 2) * 0.7 * t + radius;
      var context = this.context;

      // draw outer circle
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = "rgba(255, 200, 200," + (1 - t) + ")";
      context.fill();

      // draw inner circle
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = "rgba(255, 100, 100, 1)";
      context.strokeStyle = "white";
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // continuously repaint the map, resulting in the smooth animation of the dot
      this.mMap.triggerRepaint();

      // return `true` to let the map know that the image was updated
      return true;
    },
  };

  buildMap() {
    this.mMap = new mapboxgl.Map({
      container: "map",
      style: this.style,
      zoom: 6,
      center: [this.lng, this.lat],
      attributionControl: false,
      logoPosition: "bottom-right",
    });
    this.mMap.on("load", (event) => {
      if (this._installs.length > 0) {
        this.buildMarkers(this._installs);
        const coordinates: number[] = [
          this.installs[0].coordinateLongitude,
          this.installs[0].coordinateLatitude,
        ];
        const mid: LngLat = new LngLat(coordinates[0], coordinates[1]);
        this.mMap.setCenter(mid);
      } else if (this._coordinates.length > 0) {
        this.buildMarkerWithLatAndLongOnly(this._coordinates);
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.mMap.flyTo({
              center: [position.coords.longitude, position.coords.latitude],
            });
          });
        } else {
          this.mMap.flyTo({
            center: [-93.2761, 44.9765],
          });
        }
      }
    });
  }

  refresh() {
    for (let i = 0; i < this.mMarkers.length; i++) {
      const marker = this.mMarkers[i];
      marker.remove();
    }
    this.buildMarkers(this._installs);
  }

  buildMarkers(installs: any[]) {
    let div: HTMLElement;
    for (let i = 0; i < installs.length; i++) {
      const install = installs[i];
      const marker = new mapboxgl.Marker();
      const coordinates: number[] = [
        install.coordinateLongitude,
        install.coordinateLatitude,
      ];
      div = this.buildDialogBox(install);
      const c: LngLat = new LngLat(
        install.coordinateLongitude,
        install.coordinateLatitude
      );
      marker.setLngLat(c);
      marker.setPopup(
        (this.mPopUp = new mapboxgl.Popup({ offset: 25 }).setDOMContent(div))
      );
      marker.addTo(this.mMap);
      this.mMarkers.push(marker);
    }
  }

  buildMarkerWithLatAndLongOnly(coordinates: any[]) {
    const marker = new mapboxgl.Marker();
    const c: LngLat = new LngLat(
      coordinates[1],
      coordinates[0]
    );
    marker.setLngLat(c);
    marker.addTo(this.mMap);
    this.mMarkers.push(marker);
  }

  buildMapControls(map: mapboxgl.Map) {
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );
  }

  onClick(lat, long): any {
    console.log(lat, long);
  }

  buildDialogBox(m: any): HTMLElement {
    const div = window.document.createElement("div");
    const br = window.document.createElement("br");
    const p = window.document.createElement("p");
    const id = m.id;
    const button = window.document.createElement("button");
    // button.click = this.onClick(m.coordinateLatitude, m.coordinateLongitude)
    button.addEventListener("click", () =>
      copyTextToClipboard(
        m.coordinateLatitude.toString() + " " + m.coordinateLongitude.toString()
      )
    );
    const datepipe: DatePipe = new DatePipe("en-US");
    let formattedDate = datepipe.transform(m.whenBegin, "short");
    div.innerText += formattedDate;
    p.className = "text label-text";
    p.innerText = `Lat: ${m.coordinateLatitude}, Long: ${m.coordinateLongitude}`;
    div.appendChild(br);
    div.appendChild(p);
    div.appendChild(button);
    div.className += "data-wrapper w-content text label-text";
    button.className =
      "e-btn mat-focus-indicator mat-raised-button mat-button-base mat-primary";
    button.innerText = `Copy Lat/Long`;
    return div;
  }
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

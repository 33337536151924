import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from "@angular/forms";
import { ScoutContent } from "../../interfaces/scout-content";
import { SnackService } from "../../services/extra/snack.service";
import { AdminService } from "../../services/admin.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

export function createCounterRangeValidator(maxValue, minValue) {
  return (c: FormControl) => {
    let err = {
      rangeError: {
        given: c.value,
        max: maxValue || 10,
        min: minValue || 0,
      },
    };

    return c.value > +maxValue || c.value < +minValue ? err : null;
  };
}
@Component({
  selector: "app-scout-interval",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CounterComponent),
      multi: true,
    },
  ],
})
export class CounterComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private _snack: SnackService,
    private _adminService: AdminService,
    private _router: Router,
  ) { }
  sendCommandFormControl = new FormControl({ value: null, disabled: true });

  ngOnInit(): void {
    this.sendCommandFormControl.disable();
  }

  propagateChange: any = () => { };
  validateFn: any = () => { };

  @Input("loading") loading: boolean;
  @Input("sendCounterValue") _sendCounterValue = 0;
  @Input("pollCounterValue") _pollCounterValue = 0;
  @Input("scoutId") _scoutId = "";
  @Input("scout") _scout: ScoutContent;
  @Input("firmwareVersion") _firmwareVersion = "";
  @Output() selectEmitt = new EventEmitter();
  changed = false;
  status = "";
  counterMin = 1;
  counterMax = 1440;
  sendCounterInHours: number;
  pollCounterInHours: number;
  counterRangeMax;
  counterRangeMin;
  sendOnEachRequest: boolean;
  componentSubscription: Subscription;
  commandSubscription: Subscription;
  editCommand = false;
  isVersionNew = false;
  isVersionTwo = false;

  command: string;

  set scout(val) {
    this._scout = val;
  }

  get scout() {
    return this._scout;
  }

  set scoutId(val) {
    this._scoutId = val;
  }

  get scoutId() {
    return this._scoutId;
  }

  ngOnChanges(inputs) {
    this.determineFirmwareVersion();

    if (inputs.counterRangeMax || inputs.counterRangeMin) {
      this.validateFn = createCounterRangeValidator(
        this.counterRangeMax,
        this.counterRangeMin
      );
    }
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }

    if (this.commandSubscription) {
      this.commandSubscription.unsubscribe();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  get sendCounterValue() {
    this.sendCounterInHours = Math.round((this._sendCounterValue / 60 + Number.EPSILON) * 100) / 100;
    return this._sendCounterValue;
  }

  set sendCounterValue(val) {
    this._sendCounterValue = val;
    this.propagateChange(val);
    this.sendCounterInHours = Math.round((this._sendCounterValue / 60 + Number.EPSILON) * 100) / 100;
    this.loading = false;
  }

  get pollCounterValue() {
    this.pollCounterInHours = Math.round((this._pollCounterValue / 60 + Number.EPSILON) * 100) / 100;
    return this._pollCounterValue;
  }

  set pollCounterValue(val) {
    this._pollCounterValue = val;
    this.propagateChange(val);
    this.pollCounterInHours = Math.round((this._pollCounterValue / 60 + Number.EPSILON) * 100) / 100;
    this.loading = false;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  navigateToScout(scoutId: string) {
    this._router.navigate([`/scouts/${scoutId}`])
  }

  update() {
    this.loading = true;

    const commandObj = {
      Command: this.command,
      SenderSerial: this.scout.code,
      SendCommandOnEachRequest: this.scout.sendCommandOnEachRequest,
      DoNotCheckSyntax: true
    };

    this.commandSubscription = this._adminService.postCommand(commandObj).subscribe(
      (resp) => {
        this._snack.displaySuccess(
          "Command sent: " + this.command
        );
        this.selectEmitt.emit({ stateChanged: true });
        this.loading = false;
      },
      (err) => {
        this._snack.displayError("An error has occurred: " + err);
      }
    );
  }

  determineFirmwareVersion() {
    if (this._firmwareVersion.startsWith("2")) {
      this.isVersionTwo = true;
      // this.command = "'\\r\"\\n\"\\r\"\\n'{1," + this._sendCounterValue + "}'\\r\"\\n' "
      this.command = "{{SCT}{INT," + this._sendCounterValue + "}{SEN," + this._sendCounterValue + "}}";
    }
    else if (this._firmwareVersion != null && this._firmwareVersion != "") {
      this.isVersionNew = true;
      this.command = "{{SCT}{INT," + this._pollCounterValue + "}{SEN," + this._sendCounterValue + "}}";
    }
  }

  updateCommandString() {
    if (this.isVersionTwo) {
      // this.command = "'\\r\"\\n\"\\r\"\\n'{1," + this._sendCounterValue + "}'\\r\"\\n' ";
      this.command = "{{SCT}{INT," + this._sendCounterValue + "}{SEN," + this._sendCounterValue + "}}";

    }
    else if (this.isVersionNew) {
      this.command = "{{SCT}{INT," + this._pollCounterValue + "}{SEN," + this._sendCounterValue + "}}";
    }
  }

  editCommandChangeEvent() {
    const isEnabled: boolean = this.editCommand;
    if (isEnabled) {
      this.sendCommandFormControl.enable();
    } else {
      this.sendCommandFormControl.disable();
    }
  }
}
<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading" (refresh)="getAssetData(assetId)">
</roots-page-header>

<div class="col-12" *ngIf="installs.length">
    <app-asset-map class="map" [installs]="installs" [coordinates]="coordinates"></app-asset-map>
</div>
<div class="row row-adjustment">
    <div class="col">
        <mat-card *ngIf="asset">
            <!-- <app-asset-view [asset]="asset"></app-asset-view> -->
            <h3 class="text label-header-text">Details</h3>
            <div class="sideBySide">
                <mat-form-field class="left">
                    <mat-label> Asset</mat-label>
                    <input class="text" matInput placsseholder="Asset" [(ngModel)]="model.basicName"
                        style="font-size: 14px;" disabled>
                </mat-form-field>

                <mat-form-field class="right">
                    <mat-label>Asset Name</mat-label>
                    <input class="text" matInput placeholder="Name" [(ngModel)]="asset.basicName"
                        style="font-size: 14px;" disabled>
                </mat-form-field>
            </div>

            <mat-form-field *ngIf="activeScout">
                <mat-label>Scout Name</mat-label>
                <input class="text" matInput placeholder="Scout Name" [(ngModel)]="activeScout.name" required>
            </mat-form-field>

            <mat-form-field *ngIf="activeSensorName">
                <mat-label>Sensor Name</mat-label>
                <input class="text" matInput placeholder="Sensor Name" [(ngModel)]="activeSensorName" required>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Description</mat-label>
                <input class="text" matInput placeholder="Description" [(ngModel)]="asset.basicDescription" required>
            </mat-form-field>

            <div class="sideBySide">
                <mat-form-field class="left">
                    <mat-label>Code</mat-label>
                    <input class="text medium-text" matInput placeholder="Code" [(ngModel)]="asset.code" disabled>
                </mat-form-field>

                <mat-form-field *ngIf="activeScout" class="right">
                    <mat-label>Firmware Version</mat-label>
                    <input class="text" matInput placeholder="Firmware Version" [(ngModel)]="asset.firmwareVersion"
                        disabled>
                </mat-form-field>
            </div>

            <div style="margin-bottom: 10px;">
                <button class="col-12 text" style="color: red;" mat-button (click)="enableModelChange()"
                    *ngIf="!MODEL_CHANGE">
                    Unlock Asset Model Edit
                    <mat-icon>lock</mat-icon>
                    Edit Asset model ONLY if you know what you're doing.
                </button>
                <button class="col-12 text" style="color: green;" mat-button (click)="disableModelChange()"
                    *ngIf="MODEL_CHANGE">
                    Asset Model Edit Unlocked
                    <mat-icon>lock_open</mat-icon>
                    Edit Asset model ONLY if you know what you're doing.
                </button>
            </div>

            <mat-form-field
                *ngIf="asset.connectivityBaseScoutConnectivityCode && asset.connectivityBaseScoutConnectivityCode !== null"
                disabled>
                <mat-label> Base Connectivity Code</mat-label>
                <input class="text" matInput placeholder="Code"
                    [(ngModel)]="asset.connectivityBaseScoutConnectivityCode">
            </mat-form-field>

            <mat-form-field>
                <mat-select matInput placeholder="Asset Model" [(ngModel)]="asset.assetModelId" name="model"
                    [disabled]="MODEL_CHANGE == false">
                    <mat-option *ngFor="let model of assetModel | sort: 'code'" [value]="model.id"
                        (click)="modelChanged(model)" class="text">
                        {{ model.basicName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select matInput placeholder="Asset Status" [(ngModel)]="asset.assetStatuslId" name="status">
                    <mat-option *ngFor="let status of assetStatus | sort: 'code'" [value]="status.id" class="text">
                        {{ status.basicName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button class="text" mat-raised-button type="submit" style="width: fit-content" (click)="onUpdate(asset)"
                color="primary">Update
            </button>
            <!-- <button class="text" mat-raised-button type="submit" style="width: fit-content"
            (click)="deleteDialog(asset)" color="warn" class="close">Delete
        </button> -->
        </mat-card>
    </div>
    <div class="col">
        <mat-card class="row col-adjustment" *ngIf="scoutId && ownershipHistory" style="margin-bottom: 10px;">
            <mat-card-actions class="edges">
                <app-scout-interval [scoutId]="scoutId" [sendCounterValue]="sendInterval"
                    [pollCounterValue]="pollInterval" [scout]="scout" [firmwareVersion]="firmwareVersion">
                </app-scout-interval>
            </mat-card-actions>
            <br>
            <div class="sideBySide">
                <mat-form-field class="left">
                    <mat-label> Created At</mat-label>
                    <input class="text" matInput placeholder="Created At" [ngModel]="asset.createdAt | date: 'short'"
                        disabled>
                </mat-form-field>

                <mat-form-field class="right">
                    <mat-label> Updated At</mat-label>
                    <input class="text" matInput placeholder="Updated At" [ngModel]="asset.updatedAt | date: 'short'"
                        disabled>
                </mat-form-field>
            </div>
        </mat-card>

        <app-notes *ngIf="asset" [entityId]="asset.id" [entityCode]="asset.code" [entityType]="'Asset'" [notes]="notes"
            [isLong]="true" (noteAdded)="noteAdded($event)" class="row">
        </app-notes>

        <!--Scout configurations-->
        <app-roots-details-data-list [data]="scouts" [prop_1]="'assetName'" [prop_2]="'content'" [type]="1"
            [icon]="'widgets'" [title]="'Scouts'" [isLong]="true" class="row">
        </app-roots-details-data-list>

        <!--Asset ownership history-->
        <app-roots-details-data-list [data]="ownershipHistory" [prop_1]="'customerName'" [prop_2]="'assetName'"
            [prop_3]="'whenBegin'" [prop_4]="'whenEnd'" [type]="3" [icon]="'widgets'" [title]="'Ownership History'"
            [isLong]="true" class="row">
        </app-roots-details-data-list>

        <!--Installations-->
        <app-roots-details-data-list [data]="formattedInstalls" [prop_1]="'whenBegin'" [prop_2]="'whenEnd'"
            [prop_3]="'coordinateLatitude'" [prop_4]="'coordinateLongitude'" [prop_5]="'coordinateAltitude'" [type]="4"
            [icon]="'widgets'" [title]="'Installs'" [isLong]="true" class="row">
        </app-roots-details-data-list>
    </div>
</div>
import {
  Component,
  OnInit,
  HostBinding,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { forkJoin, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { CustomerFormComponent } from "./create/customer-form.component";
import { Region } from "../../shared/interfaces/region";
import { CustomerType } from "../../shared/interfaces/customer-type";
import { LocalStorageService } from "../../core";
import { SnackService } from "../../shared/services/extra/snack.service";
import { CustomerService } from "../../shared/services/customer.service";
import { ExtraCustomerInfo } from "../../shared/classes/extra-customer-info";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { SearchBarService } from "../../shared/services/searchbar.service";
import { EmailDialogComponent } from "../../shared/components/dialogs";
import { Customer, Scout } from "../../shared";
import { RootsActionMenuItem, ActionType } from "../../shared/interfaces/roots-action-menu-item";
import { RootsTableActionColumn } from "../../shared/interfaces/roots-table-action-column";
@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent implements OnInit, AfterViewInit, OnDestroy {
  reportBuilderEnabled = false;
  customers: Customer[];
  customersHolder: Customer[];
  scouts: Scout[];
  isCollapsed = false;
  showInactive = true;
  showSettings = false;
  count = 0;
  private currentUser: any;
  regions: any[];
  types: any[];
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create Customer",
      icon: "add",
      toolTip:
        "Create a new Customer - if an email is provided, a user will also be generated",
      type: ActionType.Create,
    },
  ];
  actionColumns: RootsTableActionColumn[];
  // Material Table init
  displayedColumns: string[] = [
    "name",
    "code",
    "customerTypeId",
    "regionId",
    //"ownership",
    "assets",
    "users",
    "userGroups",
    "edit",
  ];
  dataSource = new MatTableDataSource<Customer>([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selected = "None";
  @Output() scoutDirect = new EventEmitter<any>();
  @HostBinding("class.component-wrapper") defaultClass: boolean;
  loading = true;
  message: string;
  customerSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _router: Router,
    private _adalService: MsAdalAngular6Service,
    public _dialog: MatDialog,
    public _emailDialog: MatDialog,
    private _customerService: CustomerService,
    private _snack: SnackService,
    public searchBarService: SearchBarService
  ) {
    this.getRegionsAndTypes();
    this.title = "Customers";
    this.toolTip =
      "Find details regarding a customer's assets, users, groups, and ownership histories. Click to refresh.";
  }

  async ngOnInit() {
    await this.showCustomerList();
    this.currentUser = this._adalService.userInfo.userName;
    this.dataSource.sort = this.sort;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this.dataSource);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
    if (this._emailDialog) {
      this._emailDialog.ngOnDestroy();
    }
  }

  getRegionsAndTypes() {
    const regionRequest = this._customerService.getRegions();
    const typesRequest = this._customerService.getCustomerTypes();

    forkJoin([regionRequest, typesRequest]).subscribe((results) => {
      const regions = results[0];
      const types = results[1];
      this.regions = regions;
      this.types = types;
      this.populateRegions(regions);
      this.populateCustomerTypes(types);
    });
  }

  openDialog(): void {
    const dialogRef = this._dialog.open(CustomerFormComponent, {
      width: "60vw",
      data: "Create Customer",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
        var that = this;
        console.log(result);
        this._snack.displaySuccess("Customer created... Repopulating");
        setTimeout(function () {
          if (result.data.contact_Email) {
            const emailRef = that._dialog.open(EmailDialogComponent, {
              width: "90%",
              height: "90%",
              data: {
                to: result.data.contact_Email,
                from: "support@earthscout.com",
                name: result.data.name,
              },
            });
            emailRef.afterClosed().subscribe((result) => {
              if (result == 202) {
                setTimeout(() => {
                  that._snack.displaySuccess("Email Sent");
                }, 1000);
                that.showCustomerList();
              }
            });
          } else {
            that.showCustomerList();
          }
        }, 1000);
      }
    });
  }

  async refresh(): Promise<void> {
    this.loading = true;
    await this.showCustomerList();
  }

  async showCustomerList(): Promise<void> {
    this.customerSubscription = this._customerService.getAdminCustomers().subscribe((customers) => {
      this.customersHolder = this.customers;
      this.customers = customers;
      if (this.customers.length > 0) {
        this.loading = false;
        this._snack.displaySuccess("Customers loaded");
      }
      this.customers = this.customerTypesAndRegions(
        this.customers as ExtraCustomerInfo[]
      );

      this.dataSource.data = this.customers as ExtraCustomerInfo[];
      this.actionColumns = [
        {
          name: "name",
        },
        {
          name: "description",
        },
        {
          name: "typeName",
        },
        {
          name: "regionName",
        },
        {
          name: "details",
          icon: "edit",
          type: ActionType.Link,
          class: "mat-18",
          toolTip: "Navigate to find more asset details",
        },
      ];
      if (
        this.dataSource.data !== undefined &&
        this.dataSource.data.length > 0
      ) {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          return a.basicName < b.basicName
            ? -1
            : a.basicName > b.basicName
              ? 1
              : 0;
        });
      }
      // console.log('customers loaded: ' + this.customers);
      setTimeout(() => (this.dataSource.paginator = this.paginator));
      this.reFilter();
    });
  }

  customerTypesAndRegions(customers: ExtraCustomerInfo[]): ExtraCustomerInfo[] {
    try {
      for (let i = 0; i < customers.length; i++) {
        const customer = customers[i];
        for (let j = 0; j < this.types.length; j++) {
          const t = this.types[j];
          if (customer.customerTypeId.includes(t.value)) {
            customer.typeName = t.key;
          }
        }
        for (let j = 0; j < this.regions.length; j++) {
          const r = this.regions[j];
          if (customer.regionId.includes(r.value)) {
            customer.regionName = r.key;
          }
        }
      }
      return customers;
    } catch (error) {
      console.log("an error occurred while loading: " + error);
      //this.showCustomerList();
      this._snack.displayError("An error occurred while loading");
      this.dataSource.data = this.customers as ExtraCustomerInfo[];
    }
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.openDialog();
        break;
      default:
        break;
    }
  }

  reFilter() {
    const search = this.dataSource.filter;
    if (search.length > 0) {
      this.searchBarService.doFilter(search, this.dataSource);
    }
  }
  // navigation --------------------------------------------------------------->

  navigate(customer: Customer, key: number) {
    switch (key) {
      case 0:
        this._router.navigate([`/customers/${customer.id}`]);
        break;
      case 1:
        this._router.navigate([`/customers/${customer.id}/ownership`]);
        break;
      case 2:
        this._router.navigate([`/customers/${customer.id}/assets`]);
        break;
      case 3:
        this._router.navigate([`/customers/${customer.id}/users`]);
        break;
      case 4:
        this._router.navigate([`/customers/${customer.id}/usergroups`]);
        break;
      default:
        break;
    }
  }

  // populate --------------------------------------------------------------->

  populateRegions(regions: Region[]): object[] {
    this.regions = [];
    for (let i = 0; i < regions.length; i++) {
      const r = regions[i];
      const region = { key: r.basicName, value: r.id };
      localStorage.setItem(r.id, r.basicName);
      this.regions.push(region);
    }
    return this.regions;
  }

  populateCustomerTypes(types: CustomerType[]): object[] {
    this.types = [];
    for (let i = 0; i < types.length; i++) {
      const t = types[i];
      const type = { key: t.basicName, value: t.id };
      localStorage.setItem(t.id, t.basicName);
      this.types.push(type);
    }
    return this.types;
  }
}

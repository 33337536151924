import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reminder-dialog',
  templateUrl: './reminder-dialog.component.html',
})
export class ReminderDialogComponent {
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ReminderDialogComponent>,
  ) { }

  onNoClick() {
    this.event.emit(false);
    this.dialogRef.close();
  }

  yesClick() {
    this.event.emit(true);
  }

}

<mat-progress-bar style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0" *ngIf="loading"
  mode="query">
</mat-progress-bar>
<header *ngIf="selectedGroup">
  <!-- <app-sub-navigation [icons]="icons" [customerId]="customerId" style="position: absolute; left: 0"></app-sub-navigation> -->
  <div class="overlay">
    <h3>Group: {{ selectedGroup.basicName }}</h3>
  </div>
</header>
<div mat-dialog-content>
  <div class="col-12" *ngIf="selectedGroup">
    <div class="col-6" style="float: left;">
      <mat-form-field>
        <mat-select matInput placeholder="GET other Customers' Users and Scouts"
          (selectionChange)="customerChanged($event)" name="name" [(value)]="customer">
          <mat-option *ngFor="let customer of customers" [value]="customer">
            <p>
              <b>{{ customer.name }} </b>
            </p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-card>
        <mat-card-header>
          <mat-card-title class="no-margin">
            <mat-icon style="color: green;"
              matTooltip="Use 'Add User' button to add to group: {{selectedGroup.basicName}}"
              matTooltipPosition="above">
              group_add
            </mat-icon>
            ({{users.length}}) Available Users
          </mat-card-title>
          <!-- <mat-card-subtitle class="text small-text">
            Add users from Available to the Current groups' users
          </mat-card-subtitle> -->
        </mat-card-header>
        <hr />
        <mat-card-content>
          <div *ngIf="users.length > 0; else emptyUsers">
            <ul *ngFor="let member of users; index as i" style="padding: 0; line-break: anywhere;">
              <div *ngIf="users.length > 0; else noUsers" class="col-12 narrow-button">
                <button mat-button color="secondary" matTooltip="Navigate to {{member.name}} details"
                  matTooltipPosition="above" (click)="navigateUrl('customer-users', member)">
                  <span class="big-button-text">{{ member.name }}</span>
                  <br>
                  <span class="small-button-text">({{ member.authUsername }})</span>
                </button>
                <button color="secondary" matTooltip="Add User to {{selectedGroup.basicName}}"
                  matTooltipPosition="above" (click)="addUserToGroup(member.id)"
                  class="btn btn-primary w-25 float-right" style="background-color: green;" type="button">
                  Add
                </button>
              </div>
              <ng-template #noUsers>
                <p class="text label-text">No user found on this customer</p>
                <p class="text small-text">Refresh your search params or select another customer</p>
              </ng-template>
              <hr />
            </ul>
          </div>
          <ng-template #emptyUsers>
            <button mat-icon-button color="warn" matTooltip="Assign users to this selected group"
              matTooltipPosition="above">
              <mat-icon style="font-size: xxx-large;">close</mat-icon>
              <!-- {{ scout.scoutName }} -->
            </button>
            <p class="text label-text">No user members found!</p>
            <p class="text small-text">Double check the users assigned to this group</p>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title class="no-margin">
            <mat-icon style="color: green;"
              matTooltip="Use 'Add Scout' button to add to group: {{selectedGroup.basicName}}"
              matTooltipPosition="above">
              wifi_tethering
            </mat-icon>
            ({{availScouts.length}}) Available Scouts
          </mat-card-title>
          <!-- <mat-card-subtitle class="text small-text">
            Scouts for the currently selected customer
          </mat-card-subtitle> -->
        </mat-card-header>
        <hr />
        <mat-card-content>
          <div *ngIf="availScouts.length > 0; else emptyAvailScouts">
            <ul *ngFor="let scout of availScouts; index as i" style="padding: 0">
              <div *ngIf="scout.scoutId !== null; else noScouts" class="col-12 narrow-button">
                <button mat-button color="secondary" matTooltip="Navigate to {{scout.assetName}} details"
                  matTooltipPosition="above" (click)="navigateUrl('scouts', scout)">
                  <span class="big-button-text">{{scout.assetName}}</span>
                  <br>
                  <span *ngIf="scout.assetName != scout.scoutName" class="small-button-text">
                    ({{ scout.scoutName}})
                  </span>
                </button>
                <button color="secondary" matTooltip="Add Scout to {{selectedGroup.basicName}}"
                  matTooltipPosition="above" (click)="addScoutToGroup(scout.scoutId)"
                  class="btn btn-primary w-25 float-right" style="background-color: green;" type="button">
                  Add
                </button>
                <hr />
              </div>
              <ng-template #noScouts>
                <div class="noScoutId">
                  <button mat-icon-button color="secondary" matTooltip="Not installed. Click to install"
                    matTooltipPosition="above" (click)="navigateOwnership(scout)">
                    <mat-icon class="mat-18" style="color: red">settings</mat-icon>
                  </button>
                  {{ scout.scoutName }}
                  <br />
                  <hr />
                </div>
              </ng-template>
            </ul>
          </div>
          <ng-template #emptyAvailScouts>
            <button mat-icon-button color="warn" matTooltip="Assign assets to this customer" matTooltipPosition="above"
              (click)="openAssetDialog()">
              <mat-icon style="font-size: xxx-large;">close</mat-icon>
              <!-- {{ scout.scoutName }} -->
            </button>
            <p class="text label-text">No scouts found!</p>
            <p class="text small-text">Double check the assets assigned to this customer</p>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- right column -->
    <div class="col-6" style="float: left;">
      <mat-form-field>
        <mat-select matInput placeholder="Select another related group" (selectionChange)="groupChanged($event)"
          name="name" [(value)]="selectedGroup.id">
          <mat-option *ngFor="let group of customerGroups" [value]="group.id">
            <p>
              <b>{{ group.name }} </b>
            </p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-card>
        <mat-card-header>
          <mat-card-title class="no-margin">
            <mat-icon style="color: blue;" matTooltip="Users in the current group: {{selectedGroup.basicName}}"
              matTooltipPosition="above">
              group
            </mat-icon>
            ({{members.length}}) Users in {{ selectedGroup.basicName }}
          </mat-card-title>
          <hr />
          <!-- <mat-card-subtitle findGroupDetails class="text small-text">
            User that are in this group
          </mat-card-subtitle> -->
        </mat-card-header>
        <hr />
        <mat-card-content>
          <div *ngIf="members.length > 0; else emptyMembers">
            <ul *ngFor="let member of members; index as i" style="padding: 0">
              <div *ngIf="members.length > 0; else noMembers" class="col-12 narrow-button">
                <button mat-button color="secondary" matTooltip="Navigate to {{member.userName}} details"
                  matTooltipPosition="above" (click)="navigateUrl('users', member)">
                  <span class="big-button-text">{{member.userName}}</span>
                  <br>
                  <span class="small-button-text">({{ member.authUsername }})</span>
                </button>
                <button color="warn" matTooltip="Remove User from {{selectedGroup.basicName}}"
                  matTooltipPosition="above" (click)="removeUserFromGroup(member.id)" class="btn btn-primary w-25 float-right"
                  style="background-color: red;" type="button">
                  Remove
                </button>
              </div>
              <ng-template #noMembers>
                <p class="text label-text">No members found</p>
                <p class="text small-text">Add members from left column or refresh your search params</p>
              </ng-template>
              <hr />
            </ul>
          </div>
          <ng-template #emptyMembers>
            <button mat-icon-button color="warn" matTooltip="Assign users to this selected group"
              matTooltipPosition="above">
              <mat-icon style="font-size: xxx-large;">close</mat-icon>
              <!-- {{ scout.scoutName }} -->
            </button>
            <p class="text label-text">No user members found!</p>
            <p class="text small-text">Double check the users assigned to this group</p>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <hr />
          <mat-card-title class="no-margin">
            <mat-icon style="color: blue;" matTooltip="Scouts in the current group: {{selectedGroup.basicName}}"
              matTooltipPosition="above">
              wifi_tethering
            </mat-icon>
            ({{scouts.length}}) Scouts in {{ selectedGroup.basicName }}
          </mat-card-title>
          <hr />
          <!-- <mat-card-subtitle class="text small-text">
            Scouts in this group
          </mat-card-subtitle> -->
        </mat-card-header>
        <hr />
        <mat-card-content>
          <div *ngIf="scouts.length > 0; else emptyScouts">
            <ul *ngFor="let scout of scouts; index as i" style="padding: 0">
              <div *ngIf="scouts.length > 0; else noScoutsFound" class="col-12 narrow-button">
                <button mat-button color="secondary" matTooltip="Navigate to {{scout.scoutName}} details"
                  matTooltipPosition="above" (click)="navigateUrl('scouts', scout)">
                  <span class="big-button-text">{{ scout.assetName }}</span>
                  <br>
                  <span *ngIf="scout.assetName != scout.scoutName" class="small-button-text">
                    ({{ scout.scoutName}})
                  </span>
                </button>
                <button color="warn" matTooltip="Remove Scout from {{selectedGroup.basicName}}"
                  matTooltipPosition="above" (click)="removeScoutFromGroup(scout.id)" class="btn btn-primary w-25 float-right"
                  style="background-color: red;" type="button">
                  Remove
                </button>
              </div>
              <ng-template #noScoutsFound>
                <p class="text label-text">No scouts found</p>
                <p class="text small-text">Add scouts from left column or refresh your search params</p>
              </ng-template>
              <hr />
            </ul>
          </div>
          <ng-template #emptyScouts>
            <button mat-icon-button color="warn" matTooltip="Assign assets to this customer" matTooltipPosition="above"
              (click)="openAssetDialog()">
              <mat-icon style="font-size: xxx-large;">close</mat-icon>
              <!-- {{ scout.scoutName }} -->
            </button>
            <p class="text label-text">No scouts found!</p>
            <p class="text small-text">Double check the assets assigned to this customer</p>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="col-12" style="margin-bottom: 10px;">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title">
                    Map
                </mat-panel-title>
                <mat-panel-description class="panel-desc">
                    Scout Location
                    <mat-icon>map</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div id="map"></div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
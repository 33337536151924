<div mat-dialog-content class="superbold">You are trying to change the asset model...</div>
<p class="text">
  This may cause unknown errors for any of the assets in our systems. <br>
  Find the newly desired asset model in the dropdown and then click the update button below the user details to save.
</p>
<div>
  <div>
    <div class="col-12">
        <div class="col-4" style="float: left">
          <label *ngIf="confirmation_1">Yes</label>
          <label *ngIf="!confirmation_1">No</label>
          <br>
            <label for="confirmation_1">Would you like to continue?</label>
            <br />
            <mat-progress-bar
            *ngIf="confirmation_1"
            mode="query"
          >
          </mat-progress-bar>
            <mat-slide-toggle
              [(ngModel)]="confirmation_1"
              name="confirmation_1"
              (change)="confirmation1()"
            >
            </mat-slide-toggle>
        </div>
        <div class="col-4" style="float: left" *ngIf="confirmation_1">
          <label *ngIf="confirmation_2">Yes</label>
          <label *ngIf="!confirmation_2">No</label>
          <br>
          <label for="confirmation_2">Are you sure?</label>
          <br />
          <mat-progress-bar
            *ngIf="confirmation_2"
            mode="query"
          >
          </mat-progress-bar>
          <mat-slide-toggle
            [(ngModel)]="confirmation_2"
            name="confirmation_2"
            (change)="confirmation2()"
          >
          </mat-slide-toggle>
      </div>
    </div>
    <hr />
  </div>
</div>
<br>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="yesClicked()"
    cdkFocusInitial
    [ngClass]="{ 'disabled': confirmation_2 === false }"
  >
    Change Asset Model
  </button>
</div>

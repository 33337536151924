import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { AssetsService } from "../../shared/services/assets.service";
import {
  AssetRoutingModule,
  AssetComponent,
  AssetEditComponent,
  AssetFormComponent,
  ScannerComponent
} from ".";
import { MatModule } from "../../core";
import { ConfirmAssetModelDialogComponent } from "./edit/dialog/confirm-asset-model-dialog.component";
import { ReminderDialogComponent } from './edit/reminder-dialog/reminder-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    // ZXingScannerModule,
    FormsModule,
    ReactiveFormsModule,
    AssetRoutingModule,
    SharedModule,
    PerfectScrollbarModule,
    MatModule,
  ],
  declarations: [
    AssetComponent,
    AssetEditComponent,
    AssetFormComponent,
    ScannerComponent,
    ConfirmAssetModelDialogComponent,
    ReminderDialogComponent,
  ],
  entryComponents: [AssetFormComponent, ScannerComponent, ConfirmAssetModelDialogComponent],
  providers: [AssetsService],
})
export class AssetModule {}

<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading" [items]="actionButtons" (refresh)="refresh()"
  (actionListClicked)="actionsClicked($event)">
</roots-page-header>
<div class="col-12" *ngIf="user">
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left; box-sizing: border-box;">
    <mat-card>
      <h3 class="text label-header-text">Details</h3>
      <hr />
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput placeholder="Code" [(ngModel)]="user.code" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="user.name" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" [(ngModel)]="user.description" required>
      </mat-form-field>

      <mat-form-field>
        <mat-select matInput placeholder="Region" required [(ngModel)]="user.regionId" name="region">
          <mat-option *ngFor="let reg of regions | sort:'key'" [value]="reg.value">
            {{ reg.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select matInput placeholder="User Type" required [(ngModel)]="user.userTypeId" name="userType">
          <mat-option *ngFor="let type of types | sort:'key'" [value]="type.value">
            {{ type.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button type="submit" (click)="onUpdate(user)" color="primary">Update</button>
      <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Back to
        Users</button>
    </mat-card>
  </div>
  <app-notes *ngIf="user" [entityId]="userId" [entityCode]="user.code" [entityType]="'User'" [notes]="notes" (noteAdded)="noteAdded($event)">
  </app-notes>
  <app-roots-details-data-list [data]="[customer]" [prop_1]="'basicName'" [prop_2]="'basicDescription'"
    [prop_3]="'userId'" [prop_4]="'code'" [prop_5]="'id'" [type]="2" [icon]="widgets" [title]="'Root Customer'">
  </app-roots-details-data-list>
  <app-roots-details-data-list [data]="userGroupMembers" [prop_1]="'name'" [prop_2]="'customerName'" [type]="6"
    [icon]="widgets" [title]="'Current UserGroups'">
  </app-roots-details-data-list>
</div>
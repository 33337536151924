import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AdminService } from '../../../shared/services/admin.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-scouts',
  templateUrl: './customer-scouts.component.html'
})
export class CustomerScoutsComponent implements OnInit, OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  scouts?: {}[] = [];
  initScouts?: any[];
  title='';
  toolTip='';
  customerId = '';
  noScouts = false;
  message: string;
  loading = true;
  customer: any;
  customerAssetOwnerships: any[];
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'assetName',
    'name',
    'asoBegin',
    'asoEnd',
    'content',
    'code', 
    'firmwareVersion'
  ];
  searchSubscription: Subscription;
  adminSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _customerService: CustomerService,
    public searchBarService: SearchBarService,
    private _datePipe: DatePipe
    ) 
  {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.customerId = id;
        return id;
      }),
      mergeMap( id => {
        const getCustomerDetails = this._customerService.getCustomerDetails(id)
        .pipe(catchError((err) => of(undefined)));
        const getCustomersScouts = this._adminService.getCustomersScouts(id)
        .pipe(catchError((err) => of(undefined)));
        const getCustomerAssetOwnerships = this._adminService.getScoutCustomerOwnershipHistory(id)
        .pipe(catchError((err) => of(undefined)));
        return forkJoin([
          getCustomerDetails, 
          getCustomersScouts,
          getCustomerAssetOwnerships
        ]);
      })).subscribe(results => {
        const customer = results[0];
        const customersScouts = results[1];
        const customerAssetOwnerships = results[2];
        this.customer = customer;
        this.scouts = customersScouts;
        this.customerAssetOwnerships = customerAssetOwnerships;
        this.dataSource = new MatTableDataSource(customersScouts);
        this.title = customer.basicName + ' Scouts';
        this.toolTip = 'Details regarding a given customers Scouts';
        this.loading = false;
        this.assignAsoBeginAndEnd();
      })
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this.message = message;
    })
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
  }

  assignAsoBeginAndEnd() {
    console.log(this.dataSource.data.length);
    for (let i = 0; i < this.dataSource.data.length; i++) {
      let aso = this.customerAssetOwnerships.find(a => a.id == this.dataSource.data[i].assetOwnershipId);
      this.dataSource.data[i].asoBegin = this._datePipe.transform(new Date(aso.whenBegin), 'M/d/yyyy h:mm:ss a');
      if (aso.whenEnd) {
        this.dataSource.data[i].asoEnd = this._datePipe.transform(new Date(aso.whenEnd), 'M/d/yyyy h:mm:ss a');
      }
    }
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'asoBegin':
          return new Date(item.asoBegin);
        case 'asoEnd':
          return new Date(item.asoEnd);
        default: return item[property];
      }
    };
  }

  refresh($event) {
    this.loading = true;
    this.adminSubscription = this._adminService.getCustomersScouts(this.customerId).subscribe(results => {
      this.dataSource = new MatTableDataSource(results);
      this.assignAsoBeginAndEnd();
      this.loading = false;
    })
  }
}

<div class="card-padding">
  <h3 class="text label-header-text">Scout Interval</h3>
  <div class="margin-bottom">
    <div class="text label-text">
      Scout {{ isVersionNew ? "polling " : "" }} interval set to: 
      <input matInput type="number" class="counter" (input)="updateCommandString()"
        [(ngModel)]="pollCounterValue"> minutes
      (<b>{{ pollCounterInHours }} hours</b>)
    </div>
    <div class="text label-text" *ngIf="isVersionNew">
      Scout sending interval set to:
      <input matInput type="number" class="counter" (input)="updateCommandString()" [(ngModel)]="sendCounterValue">
      minutes
      (<b>{{ sendCounterInHours }} hours</b>)
    </div>
  </div>
  <div class="text margin-bottom" *ngIf="scout">
    <mat-slide-toggle labelPosition="before" [(ngModel)]="scout.sendCommandOnEachRequest">
      Send Command on Each ({{scout.sendCommandOnEachRequest}})
    </mat-slide-toggle>
  </div>
  <mat-form-field>
    <input matInput placeholder="Command to Send" [(ngModel)]="command" [formControl]="sendCommandFormControl" />
  </mat-form-field>
  <mat-slide-toggle class="send-command" [(ngModel)]="editCommand" name="editCommand"
    (change)="editCommandChangeEvent()">
    Toggle Command Edit
  </mat-slide-toggle>
  <div class="sideBySide">
    <p class="left interval-commands">
      {{'{'}}{{'{'}}SCT{{'}'}}{{'{'}}INT,60{{'}'}}{{'{'}}SEN,60{{'}'}}{{'}'}} for Scout 3.x.x and up
      <br>
      '\r''\n''\r''\n'{{'{'}}1,60{{'}'}}'\r''\n' for Scout 2.x.x
    </p>
    <button mat-raised-button color="primary" (click)="update()" class="right">
      Set Interval/Send Command
    </button>
  </div>
  <mat-progress-bar style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0" *ngIf="loading"
    mode="query">
  </mat-progress-bar>
</div>
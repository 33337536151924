import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { RootsActionMenuItem, ActionType } from '../../../shared';
import { AdminService } from '../../../shared/services/admin.service';
import { FwmFwiDialogComponent } from '../fwm-fwi-dialog/fwm-fwi-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FirmwareFormComponent } from '../create/firmware-form.component';
import { ItemsListDialogComponent } from '../../../shared/components/dialogs/items-list-dialog/items-list-dialog.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-firmware-models',
  templateUrl: './firmware-models.component.html'
})
export class FirmwareModelsComponent implements OnInit, OnDestroy {
  firmwares: any[];
  loading = true;
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create FWM",
      icon: "add",
      toolTip:
        "Create new FWM for Firmware - once created than you may assign a FWI to it",
      type: ActionType.Create,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "Find FWI",
      toolTip:
        'Find the FWI assigned to this FWM',
      icon: "find_in_page",
      type: 10,
    },
    {
      name: "FWM Details",
      toolTip:
        'Navigate to this FWMs details',
      icon: "edit",
      type: 11,
    }
  ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'name',
    'firmwareVersion',
    'code',
    'description',
    'whenBegin', 
    'whenEnd'
  ];
  message: string;
  instances: any [] = [];
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    public searchBarService: SearchBarService,
    public _dialog: MatDialog,
    private _router: Router
    ) {
      this.title = "Firmware Models";
      this.toolTip =
        "Find details regarding firmware instances and firmware models";
      }

  ngOnInit(): void {
    this.loading = true;
    const getFirmware = this._adminService.getFirmwareModels();
    getFirmware.subscribe(firmwares => {
      this.dataSource = new MatTableDataSource(firmwares.reverse());
      this.loading = false;

      this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
        this.message = message;
        if (message.length > 0) {
          this.searchBarService.doFilter(message, this.dataSource);
        }
      });
    });
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  getFirmwares() {
    this.loading = true;
    this.componentSubscription = this._adminService.getFirmwareModels().subscribe((firmwares) => {
      this.firmwares = firmwares.reverse();
      this.dataSource = new MatTableDataSource(firmwares.reverse());
      this.loading = false;
    });
  }
  
  fwmFwiDialog(){
    const dialogRef = this._dialog.open(FwmFwiDialogComponent, {
      width: "60vw"
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        this.getFirmwares();
      }
    });
  }

  openDialog(id: string): void {
    const obj = { title: "Create Firmware Version", id: id };
    const dialogRef = this._dialog.open(FirmwareFormComponent, {
      width: "60vw",
      data: obj,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
      }
    });
  }

  findInstanceDialog(results, instanceId: string): void {
    let found = [];
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (result.id.includes(instanceId)) {
        found.push(result);
      }
    }
    const dialogRef = this._dialog.open(ItemsListDialogComponent, {
      width: "80vw",
      height: "60vw",
      data: found,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
      }
    });
  }

  getAssetFirmwareModel(asset: any) {
    let ids: string[] = [];
    ids.push(asset.firmwareInstanceId);
    this.loading = true;
    this.componentSubscription = this._adminService.getAssetModelFirmwareModel(ids).subscribe((results) => {
      console.log(results);
      const types = [];
      for (let i = 0; i < results.length; i++) {
        const firmware = results[i];
        for (let j = 0; j < this.firmwares.length; j++) {
          const firm = this.firmwares[j];
          if (firmware.firmwareModelId.includes(firm.id)) {
            types.push(firm);
          }
        }
      }
      asset.expanded = true;
      asset.firmwares = types;
      this.loading = false;
    });
  }
  
  topMenuActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.fwmFwiDialog();
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this.loading = true;
        this.componentSubscription = this._adminService.getFirmwareInstance(item.id).subscribe(results => {
          // this.getAssetFirmwareModel(item)
          this.findInstanceDialog(results, item.firmwareInstanceId);
          this.loading = false;
          // firmwareInstanceId: "96FA01A8-1267-4367-9026-AD2D25F3B2F0"
        })
      break;  
      case 11:
        this._router.navigate([`firmwares/models/${item.id}`])
        break;
      default:
        break;
    }
  }
}
//   code: "fwm0.11.9090"
// content: null
// description: "Firmware Model v0.11.9090 Note this is one off model with bad version format, it should have read 1.0.11.9090 or better as 1.1.11.9090"
// firmwareInstanceId: "A2417C30-5716-4356-A9DD-85E52A482F3E"
// firmwareVersion: "0.11.9090"
// id: "3C55EC8C-3009-418A-AA03-B6E64E797ABB"
// name: "FirmwareModel 0.11.9090"
// whenBegin: "2021-06-15T00:00:00Z"
// whenEnd: "2021-12-31T00:00:00Z"
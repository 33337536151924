<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  [items]="actionButtons"
  (refresh)="refresh()"
  (actionListClicked)="actionsClicked($event)"
>
</roots-page-header>
<section>
  <mat-table
    #sort="matSort"
    matSort
    [dataSource]="dataSource"
    >
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef class="center w-20" mat-sort-header>
        Acknowledge
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow w-20"
      matTooltip="Click to validate unit"
      matTooltipPosition="above"
      (click)="dialog(log)"
      [ngStyle]="{'background-color': log.validatedBy !== null? 'green' : 'red'}"
      style="padding-left: 0px;"
      >
      <button mat-button>{{ log.type }}</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        View Log Details
      </mat-header-cell>
      <mat-cell
        *matCellDef="let log"
        [ngStyle]="{'background-color': log.validatedBy !== null? 'green' : 'red'}"
        (click)="navigateToLogDetail(log)"
        matTooltip="View logs for: {{ log.name }}"
        matTooltipPosition="above"
      >
      <button mat-button>{{ log.name }}</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="generate">
      <mat-header-cell *matHeaderCellDef class="center w-20" mat-sort-header>
        Create Assets
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow w-20"
      matTooltip="Generate assets from Log"
      matTooltipPosition="above"
      (click)="generate(log)"
      [ngStyle]="{'background-color': log.validatedBy !== null? 'green' : 'red'}"
      style="padding-left: 0px;"
      >
      <button mat-button><mat-icon>devices</mat-icon></button>
      <!-- <button *ngIf="log.validated" mat-button><mat-icon>add</mat-icon></button>
      <button *ngIf="!log.validated" mat-button><mat-icon>remove</mat-icon></button> -->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modelNumber">
      <mat-header-cell *matHeaderCellDef class="center w-10" mat-sort-header>
        Model Number
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow center w-10">
        <p *ngIf="log.modelNumber; else noModelNumber" class="text label-text" style="color: green;"> {{log.modelNumber}}</p>
        <ng-template #noModelNumber>
          <p class="text label-text" style="color: red;">
            N/A
          </p>
        </ng-template>
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="voltage">
      <mat-header-cell *matHeaderCellDef class="center w-10" mat-sort-header>
        <!-- <i class="material-icons log-icon" style="color: yellow">flash_on</i> -->
        Voltage
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow center w-10">
        {{ log.voltage}}
      </mat-cell>
    </ng-container>
    

    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
        Time
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow center">
        {{ log.time | date: 'short' }}
      </mat-cell>
    </ng-container>


    <!-- <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="w-10">
        #
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow w-10">
        {{ log.sensors.length }}
      </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="validatedBy">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Validated By
      </mat-header-cell>
      <mat-cell *matCellDef="let log" class="table-overflow">
        {{ log.validatedBy }}
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="sensors">
      <mat-header-cell class="center w-20" *matHeaderCellDef mat-sort-header>
        Sensors
      </mat-header-cell>
      <mat-cell
        *matCellDef="let log"
        class="table-overflow center w-20"
      >
      <button mat-button [matMenuTriggerFor]="menu">{{log.sensors.length}}</button>
      <!-- #enddocregion mat-menu-trigger-for -->
      <mat-menu #menu="matMenu">
        <button *ngFor="let sensor of log.sensors |  sort:'port'" style="min-width: fit-content;"
         mat-menu-item [ngStyle]="{'background-color': sensor.testSensor? '#eed202' : ''}"
         >Port {{sensor.port}}: {{ sensor.name }} </button>
      </mat-menu>
      <!-- <div class="col-6"
      *ngFor="let sensor of log.sensors">
        {{ sensor.name }} 
      </div> -->
      </mat-cell>
    </ng-container>


    <!-- <ng-container matColumnDef="edit">
      <mat-header-cell class="align-mid" *matHeaderCellDef>
        Details
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let log">
        <button
          mat-icon-button
          color="warn"
          (click)="navigateToLogDetail(log)"
          matTooltip="View Log's Details"
          matTooltipPosition="above"
        >
          <mat-icon class="mat-18">reorder</mat-icon>
        </button>
      </mat-cell>
    </ng-container> -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" 
    [ngClass]="{'highlight': selectedRowIndex == row.id}"
    (click)="highlight(row)">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSize]="20" [pageSizeOptions]="[2, 4, 6, 10, 20]">
  </mat-paginator>
</section>

<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading" (refresh)="refresh()">
</roots-page-header>
<div class="alert-container">
    <mat-form-field class="filter">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Battery..." #input>
    </mat-form-field>
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [disabled]="!row.alertActive">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="basicName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Alert Type
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.basicName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customerName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.customerName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assetName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Asset Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.assetName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scoutName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Scout Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.scoutName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="basicDescription">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Additional Info
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.basicDescription}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="triggeredStartDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Start Date
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.triggeredStartDate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="triggeredCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Days Active
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.triggeredCount}}
            </mat-cell>
        </ng-container>
        <ng-container *ngIf="showInactive" matColumnDef="alertActive">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Active
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.alertActive ? "Active" : "Not Active"}}
            </mat-cell>
        </ng-container>
        <ng-container *ngIf="showInactive" matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Comment
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.comment}}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td *ngIf="input.value != ''" class="mat-cell">
                No data matching the filter "{{input.value}}"
            </td>
            <td *ngIf="input.value == ''" class="mat-cell">
                There are no alerts
            </td>
        </tr>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" aria-label="Select page of alerts"></mat-paginator>
</div>
<div class="demo-button-container">
    <button mat-flat-button [disabled]="!selection.hasValue()" (click)="removeSelectedData()" class="alerts-button">
        Clear {{selection.selected.length}} Alert(s)
    </button>
    <button mat-flat-button (click)="toggleShowInactive()" class="alerts-button">
        {{showInactive ? 'Hide' : 'Show'}} Inactive
    </button>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RootsActionMenuItem, ActionType } from '../../../shared';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from '../../../shared/services/admin.service';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemsListDialogComponent } from '../../../shared/components/dialogs/items-list-dialog/items-list-dialog.component';
import { AssetsService } from '../../../shared/services/assets.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-firmware-asset-relationship',
  templateUrl: './firmware-asset-relationship.component.html'
})
export class FirmwareAssetRelationshipComponent implements OnInit, OnDestroy {
  firmwares: any[];
  loading = true;
  title = "";
  toolTip = "";
  items: RootsActionMenuItem[] = [
    {
      name: "Find FWM",
      toolTip:
        'Find the FWM with reference to this FWI',
      icon: "find_in_page",
      type: 10,
    }
  ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'name',
    'code',
    'firmwareModelId',
    'description',
    'file'
  ];
  message: string;
  searchSubscription: Subscription;
  componentSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    public searchBarService: SearchBarService,
    private _assetService: AssetsService,
    public _dialog: MatDialog,
    private _router: Router
  ) {
    this.title = "FWM - ASM Relationships";
    this.toolTip =
      "Find the combined Firmware and Asset Model relationships";
  }

  ngOnInit(): void {
    this.loading = true;
    const getAssetModelFirmwareModel = this._assetService.getAssetModelFirmwareModelsTable();
    getAssetModelFirmwareModel.subscribe(firmwares => {
      this.dataSource = new MatTableDataSource(firmwares.reverse());
      this.loading = false;

      this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
        this.message = message;
        if (message.length > 0) {
          this.searchBarService.doFilter(message, this.dataSource);
        }
      });
    });
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  findInstanceDialog(results): void {
    const dialogRef = this._dialog.open(ItemsListDialogComponent, {
      width: "60vw",
      data: results,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
      }
    });
  }

  getInstances() {
    this.loading = true;
    this.componentSubscription = this._adminService.getFirmwareInstance().subscribe((models) => {
      this.firmwares = models.reverse();
      this.loading = false;
    });
  }

  topActionsClicked(action) {
    switch (action.type) {
      case 0:
        // this.openInstanceDialog();
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this.loading = true;
        // this.getAssetFirmwareModel(item);
        break;
      case 11:
        this._router.navigate([`firmwares/instances/${item.id}`])
        break;
      default:
        break;
    }
  }
}